var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasProfileUpdated)?_c('div',[_c('p',[_vm._v("Profile Update Requested.")]),_c('vs-button',{attrs:{"icon-pack":"feather","icon":_vm.updatedProfileShown ? 'icon-x' : 'icon-eye',"color":_vm.updatedProfileShown ? 'danger' : 'primary',"type":"filled"},on:{"click":function($event){return _vm.viewUpdatedProfile()}}},[_vm._v(" "+_vm._s(_vm.updatedProfileShown ? "Close" : "Show")+" ")])],1):_vm._e(),_c('div',{staticClass:"w-full flex items-center justify-center"},[(_vm.userDetail.profileImage)?_c('a',{attrs:{"id":"profileImage","href":_vm.userDetail.profileImage,"target":"_blank"}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"w-32 h-32 object-contain rounded-full mb-6",attrs:{"src":_vm.userDetail.profileImage,"alt":""}})])]):_c('vs-avatar',{staticClass:"cursor-pointer w-32 h-32 object-contain rounded-full mb-6",attrs:{"src":_vm.defaultPP},on:{"click":function($event){return _vm.open_in_new_tab(_vm.defaultPP)}}})],1),_c('div',{staticClass:"grid grid-cols lg:grid-cols-2 gap-4"},[(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"profileImage"}},[_vm._v("AHPRA Certificate")]),(!_vm.userDetail.ahpraCertificate)?_c('p',{attrs:{"id":"ahpraCertificate"}},[_vm._v("N/A")]):(_vm.determineFileType(_vm.userDetail.ahpraCertificate) !== 'pdf')?_c('a',{attrs:{"id":"ahpraCertificate","href":_vm.userDetail.ahpraCertificate,"target":"_blank"}},[_c('img',{staticClass:"object-contain",attrs:{"src":_vm.userDetail.ahpraCertificate,"alt":""}})]):_c('a',{attrs:{"id":"ahpraCertificate","href":_vm.userDetail.ahpraCertificate,"target":"_blank"}},[_c('span',[_vm._v("Click Here To View")])])])]):_vm._e(),(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"insuranceCertificate"}},[_vm._v("Insurance Certificate")]),(!_vm.userDetail.insuranceCertificate)?_c('p',{attrs:{"id":"insuranceCertificate"}},[_vm._v(" N/A ")]):(
            _vm.determineFileType(_vm.userDetail.insuranceCertificate) !== 'pdf'
          )?_c('a',{attrs:{"id":"insuranceCertificate","href":_vm.userDetail.ahpraCertificate,"target":"_blank"}},[_c('img',{staticClass:"object-contain",attrs:{"src":_vm.userDetail.insuranceCertificate,"alt":""}})]):_c('a',{attrs:{"id":"insuranceCertificate","href":_vm.userDetail.insuranceCertificate,"target":"_blank"}},[_c('span',[_vm._v("Click here to view")])])])]):_vm._e(),_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"firstName"}},[_vm._v("First Name")]),_c('p',{attrs:{"id":"firstName"}},[_vm._v(_vm._s(_vm.userDetail.firstName))])]),_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last Name")]),_c('p',{attrs:{"id":"lastName"}},[_vm._v(_vm._s(_vm.userDetail.lastName))])]),_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('p',{staticClass:"break-all",attrs:{"id":"email"}},[_vm._v(_vm._s(_vm.userDetail.email))])]),(
        ( this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"ahpraRegistrationNumber"}},[_vm._v("Ahpra Registration Number")]),(!_vm.userDetail.ahpraRegistrationNumber)?_c('p',[_vm._v("N/A")]):_c('p',{attrs:{"id":"ahpraRegistrationNumber"}},[_vm._v(" "+_vm._s(_vm.userDetail.ahpraRegistrationNumber)+" ")])])]):_vm._e(),(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"ahpraExiryDate"}},[_vm._v("Ahpra Exiry Date")]),(!_vm.userDetail.ahpraExiryDate)?_c('p',[_vm._v("N/A")]):_c('p',{attrs:{"id":"ahpraExiryDate"}},[_vm._v(" "+_vm._s(_vm._f("date_formatter")(_vm.userDetail.ahpraExiryDate))+" ")])])]):_vm._e(),(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"indemnityInsuranceProvider"}},[_vm._v("Indemnity Insurance Provider")]),(!_vm.userDetail.indemnityInsuranceProvider)?_c('p',[_vm._v("N/A")]):_c('p',{attrs:{"id":"indemnityInsuranceProvider"}},[_vm._v(" "+_vm._s(_vm.userDetail.indemnityInsuranceProvider)+" ")])])]):_vm._e(),(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"indemnityInsuranceNumber"}},[_vm._v("Indemnity Insurance Number")]),(!_vm.userDetail.indemnityInsuranceNumber)?_c('p',[_vm._v("N/A")]):_c('p',{attrs:{"id":"indemnityInsuranceNumber"}},[_vm._v(" "+_vm._s(_vm.userDetail.indemnityInsuranceNumber)+" ")])])]):_vm._e(),(
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      )?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full"},[_c('label',{attrs:{"for":"indemnityInsuranceExpiry"}},[_vm._v("Indemnity Insurance Expiry")]),(!_vm.userDetail.indemnityInsuranceExpiry)?_c('p',[_vm._v("N/A")]):_c('p',{attrs:{"id":"indemnityInsuranceExpiry"}},[_vm._v(" "+_vm._s(_vm._f("date_formatter")(_vm.userDetail.indemnityInsuranceExpiry))+" ")])])]):_vm._e(),(_vm.userDetail.userType === 'nurse')?_c('div',{staticClass:"w-full"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }