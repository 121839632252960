<template>
  <div>
    <div v-if="hasProfileUpdated">
      <p>Profile Update Requested.</p>
      <vs-button
        icon-pack="feather"
        :icon="updatedProfileShown ? 'icon-x' : 'icon-eye'"
        :color="updatedProfileShown ? 'danger' : 'primary'"
        type="filled"
        @click="viewUpdatedProfile()">
        {{ updatedProfileShown ? "Close" : "Show" }}
      </vs-button
      >
    </div>

    <!-- <vs-row vs-align="flex-start" v-if="$store.state.AppActiveUser.userType !== 'doctorClinic'" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="role">Role</label>
          <p v-if="userDetail.userType === 'superAdmin'" id="role">
            Super Admin
          </p>
          <p v-else-if="userDetail.userType === 'subClinic'" id="role">
            Clinic Admin
          </p>
          <p v-else-if="userDetail.userType === 'subFranchise'" id="role">
            Franchise Admin
          </p>
          <p v-else id="role">
            {{ userRole }}
          </p>
        </div>
      </vs-col>
    </vs-row> -->

        <div class="w-full flex items-center justify-center">
          <a
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <div class="img-wrapper">
              <img :src="userDetail.profileImage" class="w-32 h-32 object-contain rounded-full mb-6" alt />
              <!-- <vs-avatar id="insuranceCertificate" size="110px" :src="userDetail.insuranceCertificate" /> -->
            </div>
            <!-- <vs-avatar size="100px" :src="userDetail.profileImage" id="profileImage" /> -->
          </a>
          <vs-avatar
            class="cursor-pointer w-32 h-32 object-contain rounded-full mb-6"
            @click="open_in_new_tab(defaultPP)"
            :src="defaultPP"
            v-else
          />
        </div>

      <!-- <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'doctor'"
      >
        <div class="w-full">
          <label for="signature">Signature</label>
          <br />
          <a
            id="signature"
            v-if="userDetail.signature"
            :href="userDetail.signature"
            target="_blank"
          >
            <div class="img-wrapper">
              <img :src="userDetail.signature" class="img-fluid" alt /> -->
      <!-- <vs-avatar id="insuranceCertificate" size="110px" :src="userDetail.insuranceCertificate" /> -->
      <!-- </div> -->
      <!-- <vs-avatar
              size="100px"
              :src="userDetail.signature"
              id="signature"
              v-if="userDetail.signature"
            />-->
      <!-- </a>
          <p v-else id="signature">N/A</p>
        </div>
      </vs-col> -->

    <div class="grid grid-cols lg:grid-cols-2 gap-4">
    
    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="profileImage">AHPRA Certificate</label>
        <p v-if="!userDetail.ahpraCertificate" id="ahpraCertificate">N/A</p>
        <a
          id="ahpraCertificate"
          v-else-if="determineFileType(userDetail.ahpraCertificate) !== 'pdf'"
          :href="userDetail.ahpraCertificate"
          target="_blank"
        >
            <img :src="userDetail.ahpraCertificate" class="object-contain" alt />
            <!-- <vs-avatar id="insuranceCertificate" size="110px" :src="userDetail.insuranceCertificate" />-->
          <!-- <vs-avatar id="ahpraCertificate" size="110px" :src="userDetail.ahpraCertificate" /> -->
        </a>
        <a
          id="ahpraCertificate"
          v-else
          :href="userDetail.ahpraCertificate"
          target="_blank"
        >
          <span>Click Here To View</span>
        </a>
      </div>
    </div>

    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="insuranceCertificate">Insurance Certificate</label>
        <p v-if="!userDetail.insuranceCertificate" id="insuranceCertificate">
          N/A
        </p>
        <a
          id="insuranceCertificate"
          v-else-if="
            determineFileType(userDetail.insuranceCertificate) !== 'pdf'
          "
          :href="userDetail.ahpraCertificate"
          target="_blank"
        >
            <img
              :src="userDetail.insuranceCertificate"
              class="object-contain"
              alt
            />
            <!-- <vs-avatar id="insuranceCertificate" size="110px" :src="userDetail.insuranceCertificate" /> -->
          <!-- <vs-avatar
            id="insuranceCertificate"
            size="110px"
            :src="userDetail.insuranceCertificate"
          />-->
        </a>
        <a
          id="insuranceCertificate"
          v-else
          :href="userDetail.insuranceCertificate"
          target="_blank"
        >
          <span>Click here to view</span>
        </a>
      </div>
    </div>

    <div class="w-full">
      <label for="firstName">First Name</label>
      <p id="firstName">{{ userDetail.firstName }}</p>
    </div>

    <div class="w-full">
      <label for="lastName">Last Name</label>
      <p id="lastName">{{ userDetail.lastName }}</p>
    </div>

    <div class="w-full">
      <label for="email">Email</label>
      <p class="break-all" id="email">{{ userDetail.email }}</p>
    </div>
  
    <!-- <vs-col
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="6"
      v-if="
        this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse'
      "
    >
      <div class="w-full">
        <label for="dateOfBirth">Date Of Birth</label>
        <p v-if="!userDetail.dateOfBirth" id="dateOfBirth">N/A</p>
        <p v-else id="dateOfBirth">
          {{ userDetail.dateOfBirth | date_formatter }}
        </p>
      </div>
    </vs-col> -->
    <!-- <vs-col
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="6"
      v-if="
        this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin' &&
          this.userDetail.userType !== 'clinic' &&
          this.userDetail.userType !== 'subClinic'
      "
    >
      <div class="w-full">
        <label for="contactNumber">Contact Number</label>
        <p v-if="!userDetail.contactNumber">N/A</p>
        <p v-else id="contactNumber">{{ userDetail.contactNumber }}</p>
      </div>
    </vs-col> -->
    <!-- <vs-col
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="6"
      v-if="
        this.userDetail.userType === 'nurse' ||
          this.userDetail.userType === 'doctor'
      "
    >
      <div class="w-full">
        <label for="preferredName">Preferred Name</label>
        <p v-if="!userDetail.preferredName">N/A</p>
        <p v-else id="preferredName">{{ userDetail.preferredName }}</p>
      </div>
    </vs-col> -->
    <!-- <vs-col
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="6"
      v-if="this.userDetail.userType === 'doctor'"
    >
      <div class="w-full">
        <label for="abn">ABN</label>
        <p v-if="!userDetail.abn">N/A</p>
        <p v-else id="abn">{{ userDetail.abn }}</p>
      </div>
    </vs-col> -->

    <div
      class="w-full"
      v-if="
        ( this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="ahpraRegistrationNumber">Ahpra Registration Number</label>
        <p v-if="!userDetail.ahpraRegistrationNumber">N/A</p>
        <p v-else id="ahpraRegistrationNumber">
          {{ userDetail.ahpraRegistrationNumber }}
        </p>
      </div>
    </div>

    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="ahpraExiryDate">Ahpra Exiry Date</label>
        <p v-if="!userDetail.ahpraExiryDate">N/A</p>
        <p v-else id="ahpraExiryDate">
          {{ userDetail.ahpraExiryDate | date_formatter }}
        </p>
      </div>
    </div>

    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="indemnityInsuranceProvider"
          >Indemnity Insurance Provider</label
        >
        <p v-if="!userDetail.indemnityInsuranceProvider">N/A</p>
        <p v-else id="indemnityInsuranceProvider">
          {{ userDetail.indemnityInsuranceProvider }}
        </p>
      </div>
    </div>

    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="indemnityInsuranceNumber"
          >Indemnity Insurance Number</label
        >
        <p v-if="!userDetail.indemnityInsuranceNumber">N/A</p>
        <p v-else id="indemnityInsuranceNumber">
          {{ userDetail.indemnityInsuranceNumber }}
        </p>
      </div>
    </div>

    <div
      class="w-full"
      v-if="
        (this.userDetail.userType === 'nurse' ||
        this.userDetail.userType === 'doctor') && 
        this.$isAuRegion()
      "
    >
      <div class="w-full">
        <label for="indemnityInsuranceExpiry"
          >Indemnity Insurance Expiry</label
        >
        <p v-if="!userDetail.indemnityInsuranceExpiry">N/A</p>
        <p v-else id="indemnityInsuranceExpiry">
          {{ userDetail.indemnityInsuranceExpiry | date_formatter }}
        </p>
      </div>
    </div>

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="companyName">Company Name</label>
          <p v-if="!userDetail.companyName">N/A</p>
          <p v-else id="companyName">{{ userDetail.companyName }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <label for="clinicName">Clinic Name</label>
          <p v-if="!userDetail.clinicName">N/A</p>
          <p v-else id="clinicName">{{ userDetail.clinicName }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <label for="franchiseBusinessName">Franchise Business Name</label>
          <p v-if="!userDetail.franchiseBusinessName">N/A</p>
          <p v-else id="franchiseBusinessName">
            {{ userDetail.franchiseBusinessName }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="abn">ABN</label>
          <p v-if="!userDetail.abn">N/A</p>
          <p v-else id="abn">{{ userDetail.abn }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        v-if="this.userDetail.userType === 'clinic'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full">
          <label for="abnCertificate">ABN Certificate</label>

          <div v-if="userDetail.abnCertificate">
            <div
              v-if="determineFileType(userDetail.abnCertificate) === 'pdf'"
              style="width:50%;"
            >
              <a :href="userDetail.abnCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else class="register-img-wrapper">
              <a :href="userDetail.abnCertificate" target="_blank">
                <img :src="userDetail.abnCertificate" class="img-fluid" />
              </a>
            </div>
          </div>
          <p v-else>N/A</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full">
          <label for="address">Address</label>
          <p id="address">
            {{ userDetail.address ? userDetail.address.displayAddress : "N/A" }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        v-if="userDetail.userType === 'clinic'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full">
          <label for="suburb">Suburb</label>
          <p>
            {{
              userDetail.address ? userDetail.address.suburb || "N/A" : "N/A"
            }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <label for="postCode">Post Code</label>
          <p>
            {{
              userDetail.address ? userDetail.address.postCode || "N/A" : "N/A"
            }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <label for="state">State</label>
          <p>
            {{ userDetail.address ? userDetail.address.state || "N/A" : "N/A" }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="postalAddress">Postal Address</label>
          <p v-if="!userDetail.postalAddress">N/A</p>
          <p v-else id="postalAddress">{{ userDetail.postalAddress }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="bestContactName">Best Contact Name</label>
          <p v-if="!userDetail.bestContactName">N/A</p>
          <p class="break-all" v-else id="bestContactName">{{ userDetail.bestContactName }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="bestContactEmail">Best Contact Email</label>
          <p v-if="!userDetail.bestContactEmail">N/A</p>
          <p class="break-all" v-else id="bestContactEmail">{{ userDetail.bestContactEmail }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <label for="bestContactNumber">Best Contact Number</label>
          <p v-if="!userDetail.bestContactNumber">N/A</p>
          <p class="break-all" v-else id="bestContactNumber">
            {{ userDetail.bestContactNumber }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <label for="invoiceEmail">Invoice Email</label>
          <p v-if="!userDetail.invoiceEmail">N/A</p>
          <p class="break-all" v-else id="invoiceEmail">{{ userDetail.invoiceEmail }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <label for="invoiceEmail">Invoice Email</label>
          <p v-if="!userDetail.invoiceEmail">N/A</p>
          <p v-else id="invoiceEmail">{{ userDetail.invoiceEmail }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <label for="wantCentralisebilling">Want Centralise Billing</label>
          <p id="wantCentralisebilling">
            {{ userDetail.wantCentralisebilling ? "Yes" : "No" }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col vs-type="flex" v-if="$store.state.AppActiveUser.userType !== 'doctorClinic'" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="status">Status</label>
          <p v-if="userDetail.status === 'Pending_Approval'" class="text-sm">
            Approval Pending
          </p>

          <p
            v-else-if="
              userDetail.status === 'Active' && !userDetail.hasApprovedByAdmin
            "
            class="text-sm"
          >
            Approval Pending
          </p>

          <p v-else-if="userDetail.status === 'Active'" class="text-sm">
            Enabled
          </p>
          <p v-else class="text-sm">Disabled</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        v-if="userDetail.userType === 'doctor'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full">
          <label for="prescriberNumber">Prescriber Number</label>
          <p v-if="!userDetail.prescriberNumber">N/A</p>
          <p v-else id="prescriberNumber">{{ userDetail.prescriberNumber }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        v-if="userDetail.userType === 'nurse'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full">
          <label for="nurseCosmeticExperience">Cosmetic Experience</label>
          <p v-if="!userDetail.nurseCosmeticExperience">N/A</p>
          <p v-else id="nurseCosmeticExperience">
            {{ userDetail.nurseCosmeticExperience }}
          </p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'nurse'"
      >
        <div class="w-full">
          <label for="instagram">Instagram</label>
          <p id="instagram">{{ userDetail.instagram || "N/A" }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <label for="contactNumber">Phone</label>
          <p>{{ userDetail.contactNumber || "N/A" }}</p>
        </div>
      </vs-col>
    </vs-row> -->

      <!-- <vs-row
      v-if="userDetail.userType === 'clinic'"
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="instagram">Instagram</label>
          <p>{{ userDetail.instagram || "N/A" }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="facebook">Facebook</label>
          <p>{{ userDetail.facebook || "N/A" }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="website">Website</label>
          <p>{{ userDetail.website || "N/A" }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="clinicAddress1">Clinic Address 1</label>
          <p>{{ userDetail.clinicAddress1 || "N/A" }}</p>
        </div>
      </vs-col> -->

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="isResidentialAddress">Is Residential Address</label>
          <p>{{ userDetail.isResidentialAddress || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="haveAdrenalineAndHyalaseInClinic"
            >Have Adrenaline And Hyalase In Clinic</label
          >
          <p>{{ userDetail.haveAdrenalineAndHyalaseInClinic || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="intendToSecureS4Medication"
            >Intend To Secure S4 Medication</label
          >
          <p>{{ userDetail.intendToSecureS4Medication || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Council Permits</label>
          <ClinicPhotos
            v-if="
              userDetail.councilPermits && userDetail.councilPermits.length > 0
            "
            :clinicPhotosField="userDetail.councilPermits"
            from="details"
            loadingClass="councilPermits"
            label="Please upload any required council permits (Optional)"
            popupTitle="Councli Permits"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Poisons Certificate</label>
          <ClinicPhotos
            v-if="
              userDetail.poisonsCertificate &&
                userDetail.poisonsCertificate.length > 0
            "
            :clinicPhotosField="userDetail.poisonsCertificate"
            from="details"
            loadingClass="poisonsCertificate"
            label="Please upload any required council permits (Optional)"
            popupTitle="Poisons Certificate"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Front Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicFrontPhoto &&
                userDetail.clinicFrontPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicFrontPhoto"
            from="details"
            loadingClass="clinicFrontPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Front Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Floor Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicFloorPhoto &&
                userDetail.clinicFloorPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicFloorPhoto"
            from="details"
            loadingClass="clinicFloorPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Floor Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Treatment Bed Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicTreatmentBedPhoto &&
                userDetail.clinicTreatmentBedPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicTreatmentBedPhoto"
            from="details"
            loadingClass="clinicTreatmentBedPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Treatment Bed Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Drawing Up Area Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicDrawingUpAreaPhoto &&
                userDetail.clinicDrawingUpAreaPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicDrawingUpAreaPhoto"
            from="details"
            loadingClass="clinicDrawingUpAreaPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Drawing Up Area Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Fridge Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicFridgePhoto &&
                userDetail.clinicFridgePhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicFridgePhoto"
            from="details"
            loadingClass="clinicFridgePhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Fridge Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Drug Storage Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicDrugStoragePhoto &&
                userDetail.clinicDrugStoragePhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicDrugStoragePhoto"
            from="details"
            loadingClass="clinicDrugStoragePhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Drug Storage Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Bins Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicBinsPhoto &&
                userDetail.clinicBinsPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicBinsPhoto"
            from="details"
            loadingClass="clinicBinsPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Bins Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Room Lock Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicRoomLockPhoto &&
                userDetail.clinicRoomLockPhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicRoomLockPhoto"
            from="details"
            loadingClass="clinicRoomLockPhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Room Lock Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label>Clinic Epipen And Hyalase Photo</label>
          <ClinicPhotos
            v-if="
              userDetail.clinicEpipenAndHyalasePhoto &&
                userDetail.clinicEpipenAndHyalasePhoto.length > 0
            "
            :clinicPhotosField="userDetail.clinicEpipenAndHyalasePhoto"
            from="details"
            loadingClass="clinicEpipenAndHyalasePhoto"
            label="Please upload any required council permits (Optional)"
            popupTitle="Clinic Epipen And Hyalase Photo"
          />

          <p v-else>N/A</p>
        </div>
      </vs-col> -->

      <!-- <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <div class="clinicOpeningHoursDiv">
          <span>Clinic Opening Hours</span>

          <vs-row>
            <vs-col
              v-if="
                userDetail.clinicOpeningHours &&
                  userDetail.clinicOpeningHours.mondays
              "
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Mondays</label>
                    <p>{{ userDetail.clinicOpeningHours.mondays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Tuesdays</label>
                    <p>{{ userDetail.clinicOpeningHours.tuesdays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Wednesdays</label>
                    <p>
                      {{ userDetail.clinicOpeningHours.wednesdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Thursdays</label>
                    <p>
                      {{ userDetail.clinicOpeningHours.thursdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Fridays</label>
                    <p>{{ userDetail.clinicOpeningHours.fridays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Saturdays</label>
                    <p>
                      {{ userDetail.clinicOpeningHours.saturdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full">
                    <label>Sundays</label>
                    <p>{{ userDetail.clinicOpeningHours.sundays || "N/A" }}</p>
                  </div>
                </vs-col>
              </vs-row>
            </vs-col>

            <vs-col
              v-else
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
            </vs-col>
          </vs-row>
        </div>
      </vs-row> -->

    <div
      class="w-full"
      v-if="userDetail.userType === 'nurse'"
    >
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="facebook">Facebook</label>
          <p id="facebook">{{ userDetail.facebook || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="website">Website</label>
          <p id="website">{{ userDetail.website || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="clinicName">Clinic Name</label>
          <p id="clinicName">{{ userDetail.clinicName || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="clinicAddress1">Clinic Address 1</label>
          <p id="clinicAddress1">{{ userDetail.clinicAddress1 || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="city">City</label>
          <p id="city">{{ userDetail.city || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="stateOrRegion">State/Region</label>
          <p id="stateOrRegion">{{ userDetail.stateOrRegion || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="photoId">Photo ID</label>
          <a
            v-if="userDetail.photoId"
            :href="userDetail.photoId"
            target="_blank"
          >
            <p id="photoId">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="restrictionImposed"
            >Have you ever had any restrictions imposed on your ability to
            practice?</label
          >
          <p id="restrictionImposed">
            {{ userDetail.restrictionImposed || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="aggreementOnAhpraRegistrationChanged"
            >Change in registration (Do you agree to tell us within 48hrs of any
            change in your registration status?)</label
          >
          <p id="aggreementOnAhpraRegistrationChanged">
            {{ userDetail.aggreementOnAhpraRegistrationChanged || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="aggreementOnIndemnityRegistrationChanged"
            >Change in insurance (Do you agree to tell us within 48hrs of any
            change in your insurance?)</label
          >
          <p id="aggreementOnIndemnityRegistrationChanged">
            {{ userDetail.aggreementOnIndemnityRegistrationChanged || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="rnOrEn"
            >Are you an RN or an EN (If an EN, you must read the guidelines on
            supervision and acknowledge the requirements involved. Contact us
            for more detail if unsure.)</label
          >
          <p id="rnOrEn">{{ userDetail.rnOrEn || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="expericedYearInRnOrEn"
            >How many years of experience have you had as an RN or an EN?</label
          >
          <p id="expericedYearInRnOrEn">
            {{ userDetail.expericedYearInRnOrEn || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="areasForRnOrEn">In what areas/fields?</label>
          <p id="areasForRnOrEn">{{ userDetail.areasForRnOrEn || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="cosmeticInjectorExperienceYears"
            >For how many years have you been a cosmetic injector?</label
          >
          <p id="cosmeticInjectorExperienceYears">
            {{ userDetail.cosmeticInjectorExperienceYears || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="cosmeticInjectableCourses"
            >Please list any courses in cosmetic injectables that you have
            completed.</label
          >
          <p id="cosmeticInjectableCourses">
            {{ userDetail.cosmeticInjectableCourses || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="trainingCertificate">Training Certificate</label>
          <a
            v-if="userDetail.trainingCertificate"
            :href="userDetail.trainingCertificate"
            target="_blank"
          >
            <p id="trainingCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="additionalTeachingInInjectables"
            >Please list any other teaching that you have had in injectables.
            (For instance, from another nurse? A Doctor? On-the-job training?
            From a chain clinic? Please provide some detail (names, locations,
            durations))</label
          >
          <p id="additionalTeachingInInjectables">
            {{ userDetail.additionalTeachingInInjectables || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="averageHourInCosmeticPracticePerWeek"
            >On average, how many hours of in-clinic cosmetic practice per week
            do you currently do?</label
          >
          <p id="averageHourInCosmeticPracticePerWeek">
            {{ userDetail.averageHourInCosmeticPracticePerWeek || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="planForAverageHourInCosmeticPracticePerWeek"
            >How many hours per week of in-clinic practice do you plan to
            do?</label
          >
          <p id="planForAverageHourInCosmeticPracticePerWeek">
            {{
              userDetail.planForAverageHourInCosmeticPracticePerWeek || "N/A"
            }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="numberOfPatientsTreated"
            >Roughly, how many patients do you think you have treated?</label
          >
          <p id="numberOfPatientsTreated">
            {{ userDetail.numberOfPatientsTreated || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="confidentAreasForToxinTreatment"
            >What are your confident areas for toxin treatment?</label
          >
          <p id="confidentAreasForToxinTreatment">
            {{ confidentAreasForToxin }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="confidentAreasForFillerTreatment"
            >What are your confident areas for filler treatment?</label
          >
          <p id="confidentAreasForFillerTreatment">
            {{ confidentAreasForFiller }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="areasNotConfidentIn"
            >Are there any areas that you are not confident in?</label
          >
          <p id="areasNotConfidentIn">
            {{ userDetail.areasNotConfidentIn || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="additionalAreaOfPractice"
            >Are there any other areas, products or techniques are part of your
            practice?</label
          >
          <p id="additionalAreaOfPractice">
            {{ userDetail.additionalAreaOfPractice || "N/A" }}
          </p>
        </div>
      </vs-col> -->

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full">
                <label for="cv">CV</label>
                <a v-if="userDetail.cv" :href="userDetail.cv" target="_blank">
                  <p id="cv">View</p>
                </a>
                <p v-else>N/A</p>
              </div>
            </vs-col>-->

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="hasCompletedHyalaseTraningInLastTwelveMonth"
            >Have you completed hyalase training in the last 12 months?</label
          >
          <p id="hasCompletedHyalaseTraningInLastTwelveMonth">
            {{
              userDetail.hasCompletedHyalaseTraningInLastTwelveMonth || "N/A"
            }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="hyalaseOrComplicationManagementCertificate"
            >Relevant certificates for hyalase/complication management</label
          >
          <a
            v-if="userDetail.hyalaseOrComplicationManagementCertificate"
            :href="userDetail.hyalaseOrComplicationManagementCertificate"
            target="_blank"
          >
            <p id="hyalaseOrComplicationManagementCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="hyalaseTrainingExpirationDate"
            >Hyalase Training Expiration Date</label
          >
          <p
            v-if="
              !userDetail.hyalaseTrainingExpirationDate ||
                userDetail.hyalaseTrainingExpirationDate === '1970-1-1'
            "
            id="hyalaseTrainingExpirationDate"
          >
            N/A
          </p>
          <p v-else id="hyalaseTrainingExpirationDate">
            {{ userDetail.hyalaseTrainingExpirationDate | date_formatter }}
          </p>
        </div>
      </vs-col> -->

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full">
                <label for="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth"
                  >Have you completed Basic Life Support training (BLS) in the last 12
                  months?</label
                >
                <p id="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth">
                  {{
                    userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth ||
                      "N/A"
                  }}
                </p>
              </div>
            </vs-col>-->

      <!--
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full">
                <label for="blsCertificate">BLS Certificate</label>
                <a
                  v-if="userDetail.blsCertificate"
                  :href="userDetail.blsCertificate"
                  target="_blank"
                >
                  <p id="blsCertificate">View</p>
                </a>
                <p v-else>N/A</p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full">
                <label for="blsTrainingExpirationDate"
                  >BLS Training Expiration Date</label
                >
                <p
                  v-if="
                    !userDetail.blsTrainingExpirationDate ||
                      userDetail.blsTrainingExpirationDate === '1970-1-1'
                  "
                  id="blsTrainingExpirationDate"
                >
                  N/A
                </p>
                <p v-else id="blsTrainingExpirationDate">
                  {{ userDetail.blsTrainingExpirationDate | date_formatter }}
                </p>
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full">
                <label for="hasImmunityToHepatitisB"
                  >Has immunity to Hepatitis B</label
                >
                <p id="hasImmunityToHepatitisB">
                  {{ userDetail.hasImmunityToHepatitisB || "N/A" }}
                </p>
              </div>
            </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="vaccinationCertificate">Vaccination Certificate</label>
          <a
            v-if="userDetail.vaccinationCertificate"
            :href="userDetail.vaccinationCertificate"
            target="_blank"
          >
            <p id="vaccinationCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>
-->
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="vaccinationCertificate">Vaccination Certificate</label>
          <a
            v-if="userDetail.vaccinationCertificate"
            :href="userDetail.vaccinationCertificate"
            target="_blank"
          >
            <p id="vaccinationCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="hasReadClinicalStandardsDocuments"
            >Have you read and agree to adhere to the Fresh Clinics "Clinical
            Standards" document?</label
          >
          <p id="hasReadClinicalStandardsDocuments">
            {{ userDetail.hasReadClinicalStandardsDocuments || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="hasConfirmedDeclarations"
            >I confirm that I have made a true and accurate declaration.</label
          >
          <p id="hasConfirmedDeclarations">
            {{ userDetail.hasConfirmedDeclarations || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full">
          <label for="canSendEmail">Are you happy for us to email you?</label>
          <p id="canSendEmail">{{ userDetail.canSendEmail || "N/A" }}</p>
        </div>
      </vs-col> -->
    </div>
    <!-- <div
      v-if="
        userDetail.financialDetails &&
          userDetail.financialDetails.accountHolderName !== '' &&
          ((userDetail.userType === 'doctor' &&
            userDetail.doctorType !== 'onCall') ||
            userDetail.userType === 'clinic')
      "
    >
      <vs-divider />
      <h4>Bank Detail</h4>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full">
            <label for="accountHolderName">Account Holder Name</label>
            <p id="accountHolderName">
              {{
                userDetail.financialDetails &&
                userDetail.financialDetails.accountHolderName
                  ? userDetail.financialDetails.accountHolderName
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="
            userDetail.userType === 'doctor' || userDetail.userType === 'clinic'
          "
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full">
            <label for="bsb">BSB</label>
            <p id="bsb">
              {{
                userDetail.financialDetails && userDetail.financialDetails.bsb
                  ? userDetail.financialDetails.bsb
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="
            userDetail.userType === 'doctor' || userDetail.userType === 'clinic'
          "
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full">
            <label for="accountNumber">Account Number</label>
            <p id="accountNumber">
              {{
                userDetail.financialDetails &&
                userDetail.financialDetails.accountNumber
                  ? userDetail.financialDetails.accountNumber
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>
      </vs-row>
    </div> -->
  </div>
  </div>
</template>

<script>
import ClinicOpeningHours from "../clinics/ClinicOpeningHours.vue";
import ClinicPhotos from "../clinics/ClinicPhotos";

export default {
  name: "ProfileDetail",
  components: {
    ClinicOpeningHours,
    ClinicPhotos,
  },
  data: () => ({
    defaultPP: require("@/assets/images/profile/default.png"),
    userDetail: {},
    actualUserDetail: {},
    hasProfileUpdated: false,
    updatedProfileShown: false,
    confidentAreasForToxin: "",
    confidentAreasForFiller: "",
    userRole: "",
  }),
  methods: {
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    viewUpdatedProfile() {
      let ud = {};
      if (this.updatedProfileShown === false) {
        let emailOfUser = this.userDetail.email;
        this.userDetail = this.userDetail.tempProfile[0];
        this.userDetail.userType = this.actualUserDetail.userType;
        this.userDetail.status = "Pending_Approval";
        this.userDetail.email = emailOfUser;
        this.updatedProfileShown = true;
      } else {
        this.userDetail = this.actualUserDetail;
        this.updatedProfileShown = false;
      }
    },
  },
  async created() {
    this.$vs.loading();
    this.$store
      .dispatch(
        "general/getUserDetailForProfile",
        this.$store.state.AppActiveUser._id
      )
      .then((response) => {
        this.userDetail = response.data.data;
        this.actualUserDetail = response.data.data;
        if (
          (this.userDetail.userType === "nurse" ||
            this.userDetail.userType === "doctor") &&
          this.userDetail.tempProfile &&
          this.userDetail.tempProfile.length > 0
        )
          this.hasProfileUpdated = true;

        if (this.userDetail.userType === "nurse") {
          if (this.userDetail.confidentAreasForToxinTreatment) {
            let obj = this.userDetail.confidentAreasForToxinTreatment;

            let keys = Object.keys(obj);

            let filtered = keys.filter(function (key) {
              return obj[key];
            });

            if (filtered && filtered.length > 0)
              this.confidentAreasForToxin = filtered.join(", ");
            else this.confidentAreasForToxin = "N/A";
          } else {
            this.confidentAreasForToxin = "N/A";
          }
        }

        if (this.userDetail.userType === "nurse") {
          if (this.userDetail.confidentAreasForFillerTreatment) {
            let obj = this.userDetail.confidentAreasForFillerTreatment;

            let keys = Object.keys(obj);

            let filtered = keys.filter(function (key) {
              return obj[key];
            });

            if (filtered && filtered.length > 0)
              this.confidentAreasForFiller = filtered.join(", ");
            else this.confidentAreasForFiller = "N/A";
          } else {
            this.confidentAreasForFiller = "N/A";
          }
        }

        this.userRole = this.capitalizeFirstLetter(this.userDetail.userType);
        this.$vs.loading.close();
      })
      .catch((err) => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}

label {
  font-size: 0.8rem;
}

div.vs-con-textarea {
  margin-top: 0 !important;
}

.uploadedImages {
  text-align: left !important;
}

.dateClass {
  margin-top: 0 !important;
}
</style>
